<template>
	<div>
		<div id="custom-datepicker" class="flex-center-content grey1-color">
			<div
				v-if="allow_remove_one_element && !is_range"
				class="m-r-sm cursor-pointer"
				v-on:click="remove_one_element"
			>
				<span>&#x276E;</span>
			</div>

			<VueDatePicker
				v-model="datepicker_value"
				@update:model-value="datepicker_value_changed"
				:range="is_range ? { maxRange: max_range_days } : false"
				:auto-apply="true"
				:enable-time-picker="false"
				:hide-input-icon="true"
				:clearable="false"
				input-class-name="datepicker-input"
				:placeholder="placeholder"
				:format="date_formatter"
				:month-picker="min_view == 'month'"
				:year-picker="min_view == 'year'"
				:locale="user_lang"
				:min-date="adapted_allow_from"
				:max-date="allow_to"
				@range-start="on_range_start"
				:day-class="max_range_days && range_start_date ? get_day_class : null"
			>
				<!-- Doc: https://vue3datepicker.com/slots/trigger-and-input/#dp-input -->
				<template
					#dp-input="{
						value,
						onInput,
						onEnter,
						onTab,
						onClear,
						onBlur,
						onKeypress,
						onPaste,
						isMenuOpen
					}"
				>
					<div
						class="cursor-pointer text-center full-height font-bold"
						style="min-width: 30px"
					>
						{{ value_to_emit ? date_formatter(value_to_emit) : "-" }}
					</div>
				</template>
			</VueDatePicker>
			<div
				v-if="allow_add_one_element && !is_range"
				class="m-l-sm cursor-pointer"
				v-on:click="add_one_element"
			>
				<span>&#x276F;</span>
			</div>
		</div>
	</div>
</template>

<script>
//Doc: https://vue3datepicker.com/
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import MultilingualServiceClass from "@services/multilingual-service.js";
const MultilingualService = new MultilingualServiceClass();

import moment from "moment";

export default {
	name: "CustomDatepicker",
	components: {
		VueDatePicker
	},
	props: {
		value: [String, Date, Array],
		is_range: Boolean,
		max_range_days: Number,
		min_view: String, //year | month | day
		allow_from: Date,
		allow_to: Date,
		placeholder: String,
		start_of_day: Boolean,
		end_of_day: Boolean
	},
	data() {
		return {
			datepicker_value: null,
			value_to_emit: null,
			range_start_date: null
		};
	},
	mounted() {
		if (this.value) {
			this.set_value(
				this.is_range
					? this.value.map((item) => moment(item).toDate())
					: moment(this.value).toDate(),
				false
			);
		}
	},
	methods: {
		set_value(value, emit = false) {
			if (!value) {
				this.datepicker_value = null;
				return;
			}

			if (!this.is_range) {
				let date = new Date(value);

				if (
					this.adapted_allow_from &&
					moment(date).isBefore(this.adapted_allow_from, this.min_view)
				) {
					emit = true;
					date = new Date(this.adapted_allow_from);
				} else if (
					this.allow_to &&
					moment(date).isAfter(this.allow_to, this.min_view)
				) {
					emit = true;
					date = new Date(this.allow_to);
				}

				switch (this.min_view) {
					case "year":
						this.datepicker_value = date.getFullYear();
						break;
					case "month":
						this.datepicker_value = {
							month: date.getMonth(),
							year: date.getFullYear()
						};
						break;
					default:
						this.datepicker_value = date;
				}
			} else {
				let date_from = new Date(value[0]);
				let date_to = new Date(value[1]);

				if (
					this.adapted_allow_from &&
					moment(date_from).isBefore(this.adapted_allow_from)
				) {
					emit = true;
					date_from = new Date(this.adapted_allow_from);
				} else if (this.allow_to && moment(date_to).isAfter(this.allow_to)) {
					emit = true;
					date_to = new Date(this.allow_to);
				}

				if (
					this.max_range_days &&
					moment(date_to).diff(date_from, "days") > this.max_range_days
				) {
					date_from = moment(date_to)
						.subtract(this.max_range_days, "days")
						.toDate();
				}

				switch (this.min_view) {
					case "year":
						this.datepicker_value = [
							date_from.getFullYear(),
							date_to.getFullYear()
						];
						break;
					case "month":
						this.datepicker_value = [
							{
								month: date_from.getMonth(),
								year: date_from.getFullYear()
							},
							{
								month: date_to.getMonth(),
								year: date_to.getFullYear()
							}
						];
						break;
					default:
						this.datepicker_value = [date_from, date_to];
				}
			}

			this.set_value_to_emit(emit);
		},
		set_value_to_emit(emit = false) {
			if (!this.datepicker_value) this.value_to_emit = null;

			switch (this.min_view) {
				case "month":
					this.value_to_emit = this.is_range
						? this.datepicker_value.map(
								(item) => new Date(item.year, item.month)
						  )
						: new Date(this.datepicker_value.year, this.datepicker_value.month);
					break;
				case "year":
					this.value_to_emit = this.is_range
						? this.datepicker_value.map((item) => new Date(item, 0))
						: new Date(this.datepicker_value, 0);
					break;
				default:
					this.value_to_emit = this.is_range
						? this.datepicker_value.map((item) => new Date(item))
						: new Date(this.datepicker_value);
			}

			if (emit) this.emit(this.value_to_emit);
		},
		emit(value) {
			let result = value;
			if (this.start_of_day) {
				result = moment(value).startOf("day").toDate();
			} else if (this.end_of_day) {
				result = moment(value).endOf("day").toDate();
			}

			this.$emit("input", result);
		},
		date_formatter(date) {
			let result;

			switch (this.min_view) {
				case "year":
					result = this.is_range
						? date.map((item) => String(this.$format_date_to_year_number(item)))
						: String(this.$format_date_to_year_number(date));
					break;
				case "month":
					result = this.is_range
						? date.map((item) => this.$format_date_to_month(item))
						: this.$format_date_to_month(date);
					break;
				default:
					result = this.is_range
						? date.map((item) => this.$format_date_to_day(item))
						: this.$format_date_to_day(date);
			}

			if (this.is_range) result = result.join(" / ");

			return result;
		},
		datepicker_value_changed() {
			this.set_value_to_emit(true);
		},
		remove_one_element() {
			const new_date = moment(this.value_to_emit)
				.subtract(1, this.min_view || "day")
				.toDate();
			this.set_value(new_date, true);
		},
		add_one_element() {
			const new_date = moment(this.value_to_emit)
				.add(1, this.min_view || "day")
				.toDate();
			this.set_value(new_date, true);
		},
		on_range_start(value) {
			this.range_start_date = value;
		},
		get_day_class(date) {
			if (
				this.range_start_date &&
				Math.abs(moment(date).diff(this.range_start_date, "days")) >
					this.max_range_days
			)
				return "cursor-not-allowed";
		}
	},
	computed: {
		user_lang() {
			return MultilingualService.get_user_app_lang();
		},
		allow_add_one_element() {
			if (!this.allow_to) return true;
			else if (!this.value_to_emit) return false;
			else
				return moment(this.value_to_emit).isBefore(
					this.allow_to,
					this.min_view
				);
		},
		allow_remove_one_element() {
			if (!this.adapted_allow_from) return true;
			else if (!this.value_to_emit) return false;
			else
				return moment(this.value_to_emit).isAfter(
					this.adapted_allow_from,
					this.min_view
				);
		},
		adapted_allow_from() {
			if (!this.allow_from) return null;

			switch (this.min_view) {
				case "year":
					return moment(this.allow_from).startOf("year").toDate();
					break;
				case "month":
					return moment(this.allow_from).startOf("month").toDate();
					break;
				default:
					return moment(this.allow_from).startOf("day").toDate();
					break;
			}
		}
	},
	watch: {
		value(new_value, old_value) {
			this.set_value(new_value, false);
		},
		min_view() {
			this.set_value(this.value_to_emit, true);
		},
		allow_from() {
			this.set_value(this.value_to_emit, true);
		},
		max_range_days() {
			this.set_value(this.value_to_emit, true);
		}
	}
};
</script>

<style lang="css">
.dp__theme_light {
	--dp-primary-color: var(--custom-primary);
	--dp-text-color: var(--grey1);
	--dp-font-family: var(--main-font-family);
}
#custom-datepicker {
	min-height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
.dp__instance_calendar {
	font-weight: 600;
}
</style>
