<template>
	<div v-if="tickets">
		<div v-if="loading_export_tickets" class="flex-center-content">
			<Loader size="25" />
		</div>
		<div v-else>
			<button v-on:click="export_tickets" class="btn btn-primary">
				<Icon icon="export" />
			</button>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";

import TicketServiceClass from "@services/user-services/ticket-service";
import UserServiceClass from "@services/user-services/user-service";
import UserFinderClass from "@src/user/application/user-finder.js";
import OwnedUsersFinderClass from "@src/user/application/owned-users-finder";

const TicketService = new TicketServiceClass();
const UserService = new UserServiceClass();
const UserFinder = new UserFinderClass();
const OwnedUsersFinder = new OwnedUsersFinderClass();

export default {
	name: "TicketsExport",
	components: {
		Loader
	},
	props: {
		tickets: Array
	},
	data() {
		return {
			loading_export_tickets: false
		};
	},
	methods: {
		async export_tickets() {
			this.loading_export_tickets = true;

			try {
				const faulty_equipments =
					await TicketService.get_ticketing_faulty_equipments();

				const export_props = [
					{
						key: "creation",
						label: this.$t("ticketing.ticket_entity.creation"),
						formatter: (value) => {
							return this.$format_date_to_day(value);
						}
					},
					{
						key: "location_custom_id",
						label: this.$t("ticketing.location_id")
					},
					{
						key: "location_name",
						label: this.$t("location.name")
					},
					{
						key: "status",
						label: this.$t("general.status"),
						formatter: async (status_id) => {
							const all_status = await TicketService.get_ticketing_status();

							return all_status[status_id] ? all_status[status_id].name : null;
						}
					},
					{
						key: "title",
						label: this.$t("ticketing.ticket_entity.title")
					},
					{
						key: "description",
						label: this.$t("general.description")
					},
					{
						key: "faulty_equipments",
						label: this.$t("ticketing.ticket_entity.faulty_equipments"),
						formatter: (value) => {
							if (value && value.length > 0) {
								return value
									.map((item) => faulty_equipments[item].name)
									.join(" ");
							} else return null;
						}
					},
					{
						key: "assigned_to",
						label: this.$t("ticketing.ticket_entity.assigned_to"),
						formatter: async (assigned_to_user_id) => {
							const current_user = await UserFinder.get_user();

							if (assigned_to_user_id == current_user.id) {
								return (
									current_user.first_name +
									(current_user.last_name ? " " + current_user.last_name : "")
								);
							} else if (
								UserService.user_has_role("supervisor") ||
								UserService.user_has_role("admin")
							) {
								const maintainer = await OwnedUsersFinder.get_owned_user(
									"maintainer",
									assigned_to_user_id
								);

								return maintainer
									? maintainer.first_name +
											(maintainer.last_name ? " " + maintainer.last_name : "")
									: "";
							} else if (UserService.user_has_role("maintainer")) {
								return this.$t("header.admin");
							}
						}
					}
				];

				let row1 = [];

				export_props.forEach((prop) => {
					row1.push(prop.label);
				});

				let result = [row1];
				for (let index in this.tickets) {
					const ticket = this.tickets[index];

					let row = [];

					for (let index2 in export_props) {
						const prop = export_props[index2];
						let value = ticket[prop.key];
						if (prop.formatter) value = await prop.formatter(value);
						row.push(value || null);
					}

					result.push(row);
				}

				this.$download_array_as_xlsx_file(result, "Tickets export");
			} catch (error) {
			} finally {
				this.loading_export_tickets = false;
			}
		}
	}
};
</script>
