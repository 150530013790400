import Joi from "joi";
import Validator from "@validators/validator";

const metrics_schema = Joi.object({
	mae: Joi.number(),
	q95ae: Joi.number(),
	max_ae: Joi.number(),
	rmse: Joi.number()
});
const stats_schema = Joi.object({
	maximum: Joi.number(),
	minimum: Joi.number(),
	average: Joi.number()
});

const schema = Joi.object({
	production: Joi.object({
		metrics: metrics_schema,
		forecast_stats: stats_schema,
		measurement_stats: stats_schema
	}),
	consumption: Joi.object({
		metrics: metrics_schema,
		forecast_stats: stats_schema,
		measurement_stats: stats_schema
	})
});
export default new Validator(schema);
