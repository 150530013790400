<template>
	<div
		v-if="asset"
		class="flex-vertical-center gap-s white grey2-bg font-xs p-l-xs p-r-xs p-t-xxs p-b-xxs b-r-md"
	>
		<div class="no-wrap">
			{{ asset.name }}
		</div>
		<div class="cursor-pointer" v-on:click="remove_asset">
			<Icon icon="close" size="8" />
		</div>
	</div>
</template>

<script>
import AssetFinderClass from "../../../../../../../src/asset/application/asset-finder.js";
const AssetFinder = new AssetFinderClass();

export default {
	name: "HeaderSelectedAsset",
	props: {
		asset_id: String
	},
	data() {
		return {
			asset: null
		};
	},
	mounted() {
		this.get_asset();
	},
	methods: {
		async get_asset() {
			try {
				this.asset = null;
				this.asset = await AssetFinder.get_asset(this.asset_id);
			} catch (error) {}
		},
		remove_asset() {
			this.$emit("remove_asset", this.asset_id);
		}
	},
	watch: {
		asset_id() {
			this.get_asset();
		}
	}
};
</script>
