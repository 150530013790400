import AssetsGroupSummaryFinderInterfaceClass from "../infrastructure/assets-group-summary-finder-interface.js";
import AssetsGroupSummaryFinderValidator from "../domain/assets-group-summary-finder-validator.js";
import AssetsGroupSummaryStoreUtilClass from "./utils/assets-group-summary-store-util.js";

import AppError from "@classes/errors/app_error";
import moment from "moment";

export default class {
	constructor() {
		this.validator = AssetsGroupSummaryFinderValidator;
		this.backend_interface = new AssetsGroupSummaryFinderInterfaceClass();
		this.store_util = null;
	}

	async get_default_assets_group_summary(force_download = null) {
		return await this.get_assets_group_summary("default", force_download);
	}

	async get_assets_group_summary(assets_group_id, force_download = null) {
		try {
			this.store_util = new AssetsGroupSummaryStoreUtilClass(assets_group_id);

			var cached_asset = this.store_util.get_data();
			if (cached_asset && !force_download) return cached_asset;

			const interface_result =
				await this.backend_interface.get_assets_group_summary(assets_group_id);
			const validated_assets_group_summary =
				this.validator.validate(interface_result);

			this.store_util.set_data(
				validated_assets_group_summary,
				moment().add(5, "minutes").toDate()
			);

			return this.store_util.get_data();
		} catch (e) {
			throw new AppError(
				"error_get_assets_group_summary",
				"general.errors.general_error",
				e
			);
		}
	}
}
