<template>
	<div class="full-width full-height relative">
		<div class="full-width full-height flex-center-content" v-if="loading">
			<LoaderBar
				style="width: 80%"
				:percentage="loading_data.processed_percentage"
				:show_percentage="true"
			/>
		</div>
		<div class="p-sm" v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else class="absolute full-width full-height">
			<div></div>
			<div class="flex-auto-full-height-content">
				<div class="header border-b p-t p-b">
					<div class="flex-center-content gap-s">
						<button class="btn btn-primary" v-on:click="download_excel">
							{{ $t("asset_energy_panel.download_excel") }}
						</button>
						<button class="btn btn-primary" v-on:click="download_csv">
							{{ $t("asset_energy_panel.download_csv") }}
						</button>
					</div>
				</div>
				<div class="content relative">
					<div class="absolute full-width full-height">
						<AssetsDataTable
							class="full-width full-height"
							:assets_table_data="table_data"
							:resolution="filter.resolution"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import LoaderBar from "../../../../sub_components/LoaderBar.vue";
import AssetsDataTable from "./AssetsDataTable.vue";

import moment from "moment";
import AssetFinderClass from "../../../../../../../src/asset/application/asset-finder.js";
import AssetHourlyEnergyFinderClass from "../../../../../../../src/asset-energy-data/application/asset-hourly-energy-finder.js";
import AssetDailyEnergyFinderClass from "../../../../../../../src/asset-energy-data/application/asset-daily-energy-finder.js";
import AssetMonthlyEnergyFinderClass from "../../../../../../../src/asset-energy-data/application/asset-monthly-energy-finder.js";

const AssetFinder = new AssetFinderClass();
const AssetHourlyEnergyFinder = new AssetHourlyEnergyFinderClass();
const AssetDailyEnergyFinder = new AssetDailyEnergyFinderClass();
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();

export default {
	name: "AssetsDataDownloadReport",
	components: {
		Alert,
		LoaderBar,
		AssetsDataTable
	},
	props: {
		filter: Object
	},
	data() {
		return {
			loading: true,
			error: null,
			table_data: null,
			loading_data: {
				total_promises: 0,
				processed_promises: 0,
				processed_percentage: 0
			}
		};
	},
	async mounted() {
		this.generate_report_data();
	},
	methods: {
		async generate_report_data() {
			this.loading = true;

			try {
				const assets = {};
				const assets_ids = this.filter.assets_ids;

				for (let index in assets_ids) {
					const asset_id = assets_ids[index];
					const asset = await AssetFinder.get_asset(asset_id);

					assets[asset_id] = asset;
				}

				let table_data = [];

				let first_row = ["Time"];
				for (let asset_id in assets) {
					const asset = assets[asset_id];
					first_row.push(asset.name);
				}
				table_data.push(first_row);

				let new_table_row_value = [];
				for (let index = 0; index < assets_ids.length; index++) {
					new_table_row_value.push(null);
				}

				const date_from = this.filter.date[0];
				const date_to = this.filter.date[1];

				switch (this.filter.resolution) {
					case "day":
						const filter_date_days_diff = moment(date_to).diff(
							date_from,
							"days"
						);
						let days_to_download = [date_from];
						for (let x = 1; x <= filter_date_days_diff; x++) {
							const day_to_add = moment(date_from).add(x, "days").toDate();
							days_to_download.push(day_to_add);
						}

						var promises = [];
						var assets_energy_measurements = {};
						for (let asset_id in assets) {
							assets_energy_measurements[asset_id] = {};

							for (let index in days_to_download) {
								const day_to_download = days_to_download[index];

								promises.push(async () => {
									const asset_day_energy_measurements =
										await AssetHourlyEnergyFinder.get_asset_day_hourly_energy(
											asset_id,
											day_to_download,
											true
										);

									assets_energy_measurements[asset_id][day_to_download] =
										asset_day_energy_measurements;
								});
							}
						}

						await this.$execute_promise_functions_in_batches(
							promises,
							6,
							this.loading_data
						);

						var assets_hourly_energy_measurements = {};
						function add_table_row(formatted_time) {
							assets_hourly_energy_measurements[formatted_time] = [];
							for (let index = 0; index < assets_ids.length; index++) {
								assets_hourly_energy_measurements[formatted_time].push(null);
							}
						}

						for (let index in days_to_download) {
							const day_to_download = days_to_download[index];

							var assets_index = 0;
							for (let asset_id in assets) {
								const asset_day_energy_measurements =
									assets_energy_measurements[asset_id][day_to_download];

								if (!asset_day_energy_measurements) continue;

								asset_day_energy_measurements.forEach(
									(day_hour_energy_measurements) => {
										const time = day_hour_energy_measurements.time;
										const formatted_time =
											moment(time).format("YYYY-MM-DD HH:mm");

										if (!assets_hourly_energy_measurements[formatted_time]) {
											add_table_row(formatted_time);
										}

										assets_hourly_energy_measurements[formatted_time][
											assets_index
										] = this.$is_number(
											day_hour_energy_measurements.energy_production
										)
											? this.$format_number(
													day_hour_energy_measurements.energy_production,
													2
											  )
											: null;
									}
								);

								assets_index++;
							}
						}

						const formatted_times = Object.keys(
							assets_hourly_energy_measurements
						);
						const ordered_times = formatted_times.sort((a, b) => b - a);

						ordered_times.forEach((time) => {
							const formatted_time = this.$format_date_to_day_hour_minute(time);
							const assets_time_energy_measurements =
								assets_hourly_energy_measurements[time];

							let table_row = [formatted_time];
							table_row = table_row.concat(assets_time_energy_measurements);

							table_data.push(table_row);
						});

						break;
					case "month":
						var promises = [];
						var assets_daily_energy_measurements = {};
						for (let asset_id in assets) {
							assets_daily_energy_measurements[asset_id] = {};

							promises.push(async () => {
								const asset_monthly_energy_measurements =
									await AssetDailyEnergyFinder.get_asset_daily_energy(
										asset_id,
										date_from,
										date_to
									);

								assets_daily_energy_measurements[asset_id] =
									asset_monthly_energy_measurements;
							});
						}

						await this.$execute_promise_functions_in_batches(promises, 6);

						const filter_months_days_diff = moment(date_to).diff(
							date_from,
							"days"
						);
						let months_days_to_download = [
							moment(date_from).format("YYYY-MM-DD")
						];
						for (let x = 1; x <= filter_months_days_diff; x++) {
							const day_to_add = moment(date_from)
								.add(x, "days")
								.format("YYYY-MM-DD");
							months_days_to_download.push(day_to_add);
						}

						months_days_to_download.forEach((month) => {
							let table_row = [this.$format_date_to_day(month)];
							table_row = table_row.concat(new_table_row_value);

							let index = 1;
							for (let asset_id in assets) {
								const asset_daily_energy_measurements =
									assets_daily_energy_measurements[asset_id];

								const asset_day_energy_measurements =
									asset_daily_energy_measurements.find((item) => {
										return moment(month).isSame(item.time, "day");
									});

								table_row[index] =
									asset_day_energy_measurements &&
									this.$is_number(
										asset_day_energy_measurements.energy_production
									)
										? this.$format_number(
												asset_day_energy_measurements.energy_production,
												2
										  )
										: null;

								index++;
							}

							table_data.push(table_row);
						});
						break;
					case "year":
						var promises = [];
						var assets_monthly_energy_measurements = {};
						for (let asset_id in assets) {
							assets_monthly_energy_measurements[asset_id] = {};

							promises.push(async () => {
								const asset_monthly_energy_measurements =
									await AssetMonthlyEnergyFinder.get_asset_monthly_energy_by_date(
										asset_id,
										date_from,
										date_to
									);

								assets_monthly_energy_measurements[asset_id] =
									asset_monthly_energy_measurements;
							});
						}

						await this.$execute_promise_functions_in_batches(promises, 6);

						const filter_date_months_diff = moment(date_to).diff(
							date_from,
							"months"
						);
						let months_to_download = [moment(date_from).format("YYYY-MM")];
						for (let x = 1; x <= filter_date_months_diff; x++) {
							const day_to_add = moment(date_from)
								.add(x, "months")
								.format("YYYY-MM");
							months_to_download.push(day_to_add);
						}

						months_to_download.forEach((month) => {
							let table_row = [this.$format_date_to_month(month)];
							table_row = table_row.concat(new_table_row_value);

							let index = 1;
							for (let asset_id in assets) {
								const asset_monthly_energy_measurements =
									assets_monthly_energy_measurements[asset_id];

								const asset_month_energy_measurements =
									asset_monthly_energy_measurements.find((item) => {
										return moment(month).isSame(item.time, "month");
									});

								table_row[index] =
									asset_month_energy_measurements &&
									this.$is_number(
										asset_month_energy_measurements.energy_production
									)
										? this.$format_number(
												asset_month_energy_measurements.energy_production,
												2
										  )
										: null;

								index++;
							}

							table_data.push(table_row);
						});
						break;
				}

				this.table_data = table_data;
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		download_excel() {
			this.$download_array_as_xlsx_file(this.table_data, "Data export");
		},
		download_csv() {
			this.$download_array_as_csv_file(this.table_data, "Data export");
		}
	},
	watch: {
		filter() {
			this.generate_report_data();
		}
	}
};
</script>
