<template lang="html">
	<div class="full-width p-sm border-b">
		<div class="display-flex-space-between">
			<div class="display-flex gap-s">
				<div class="display-flex-vertical-space-between gap-xs">
					<div>
						<span>{{ $t("asset_reports.report_type") }}</span>
					</div>
					<div>
						<Multiselect
							id="report-type-select"
							v-model="filter.report_type"
							:close_on_select="true"
							:multiple="false"
							:options="report_type_select_options"
							label="label"
							track_by="key"
						/>
					</div>
				</div>

				<div
					v-if="filter.report_type && filter.report_type != 'data_download'"
					class="display-flex gap-s"
				>
					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							{{ $t("general.location") }}
						</div>
						<div>
							<div style="min-width: 250px">
								<LocationSelect v-model="filter.asset_id" :multiple="false" />
							</div>
						</div>
					</div>

					<div v-if="filter.asset_id" class="display-flex gap-s">
						<div v-if="loading" class="flex-center-content m-l">
							<Loader size="20" />
						</div>
						<div v-else-if="!filter.date" class="flex-vertical-bottom">
							<Alert type="info" message="general.errors.no_data_available" />
						</div>
						<div
							v-else-if="
								filter.report_type == 'standard' ||
								filter.report_type == 'injection0'
							"
							class="display-flex-vertical-space-between gap-xs"
						>
							<div>
								<span>{{ $t("general.period") }}</span>
							</div>
							<div class="display-flex gap-xs">
								<div>
									<Datepicker
										min_view="month"
										:is_range="true"
										:allow_from="min_datepicker_date"
										:allow_to="max_datepicker_date"
										v-model="filter.date"
									/>
								</div>
							</div>
						</div>
						<div
							v-else-if="
								filter.report_type == 'monthly_savings' ||
								filter.report_type == 'monthly_savings_2'
							"
							class="display-flex"
						>
							<div class="display-flex-vertical-space-between gap-xs">
								<div>
									{{ $t("general.period") }}
								</div>
								<div>
									<Datepicker
										min_view="month"
										:allow_from="min_datepicker_date"
										:allow_to="max_datepicker_date"
										v-model="filter.date"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-else-if="filter.report_type == 'data_download'"
					class="display-flex gap-s"
				>
					<div class="display-flex-vertical-space-between gap-xs">
						<div>
							{{ $t("alerts.assets") }}
						</div>
						<div>
							<div style="min-width: 250px">
								<LocationSelect v-model="filter.assets_ids" :multiple="true" />
							</div>
						</div>
					</div>
					<div
						v-if="filter.assets_ids && filter.assets_ids.length > 1"
						class="display-flex-vertical-space-between gap-xs"
					>
						<div>
							{{ $t("asset_reports.resolutions") }}
						</div>
						<div>
							<div style="min-width: 250px">
								<Multiselect
									v-model="filter.resolution"
									:close_on_select="true"
									:multiple="false"
									:options="report_resolution_select_options"
									label="label"
									track_by="key"
								/>
							</div>
						</div>
					</div>

					<div v-if="loading" class="flex-vertical-center">
						<Loader size="20" />
					</div>
					<div
						v-else-if="
							filter.resolution && available_min_date && available_max_date
						"
						class="display-flex-vertical-space-between gap-xs"
					>
						<div class="display-flex gap-s">
							<div>
								{{ $t("general.period") }}
							</div>
							<div v-if="period_selected_range_string" class="grey2-color">
								({{ period_selected_range_string }})
							</div>
						</div>
						<div>
							<Datepicker
								:is_range="true"
								:max_range_days="datepicker_max_range_days"
								:min_view="filter.resolution"
								:allow_from="min_datepicker_date"
								:allow_to="max_datepicker_date"
								v-model="filter.date"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="flex-vertical-bottom gap-s">
				<button class="btn btn-secondary" v-on:click="reset_filter">
					{{ $t("general.clean") }}
				</button>
				<button
					v-if="
						(filter.asset_id ||
							(filter.assets_ids && filter.assets_ids.length > 1)) &&
						filter.date
					"
					class="btn btn-primary"
					v-on:click="generate_report"
				>
					{{ $t("asset_reports.generate_report") }}
				</button>
			</div>
		</div>

		<div
			v-if="filter.assets_ids && filter.assets_ids.length > 0"
			class="display-flex gap-s p-t overflow-x-scroll"
		>
			<HeaderSelectedAsset
				v-for="asset_id in filter.assets_ids"
				:asset_id="asset_id"
				v-on:remove_asset="remove_selected_asset"
			/>
		</div>
	</div>
</template>

<script>
import LocationSelect from "@form_components/LocationSelect2.vue";

import Loader from "@loader";
import Alert from "@alert";
import Datepicker from "@form_components/Datepicker";
import Multiselect from "@form_components/Multiselect";
import HeaderSelectedAsset from "./HeaderSelectedAsset.vue";

import moment from "moment";

import AssetEnergyAvailableDateFinderClass from "@src/asset-energy-data/application/asset-energy-available-date-finder";
const AssetEnergyAvailableDateFinder =
	new AssetEnergyAvailableDateFinderClass();

export default {
	name: "AssetReportsHeader",
	components: {
		LocationSelect,
		Loader,
		Alert,
		Datepicker,
		Multiselect,
		HeaderSelectedAsset
	},
	data() {
		return {
			loading: false,
			filter: {
				asset_id: null,
				assets_ids: null,
				report_type: null,
				date: null,
				resolution: null
			},
			available_max_date: new Date(),
			available_min_date: new Date("2020-01"),
			assets_ids_available_dates_cache: null
		};
	},
	methods: {
		reset_filter() {
			this.filter = {
				asset_id: null,
				assets_ids: null,
				report_type: null,
				date: null,
				resolution: null
			};
		},
		async filter_asset_id_changed() {
			try {
				this.loading = true;
				this.filter.date = [];

				const asset_id = JSON.parse(JSON.stringify(this.filter.asset_id));

				this.available_min_date =
					await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
						asset_id
					);
				this.available_max_date =
					await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
						asset_id
					);

				if (!this.available_min_date || !this.available_max_date) return;

				if (
					this.filter.report_type == "monthly_savings" ||
					this.filter.report_type == "monthly_savings_2"
				) {
					this.filter.date = moment(this.available_max_date)
						.startOf("month")
						.toDate();
				} else {
					this.filter.date[1] = moment(this.available_max_date)
						.startOf("month")
						.toDate();
					this.filter.date[0] = moment(this.filter.date[1])
						.subtract(3, "months")
						.startOf("month")
						.toDate();
				}
			} catch (error) {
			} finally {
				this.loading = false;
			}
		},
		generate_report() {
			this.$emit("generate_report", this.filter);
		},
		async filter_assets_ids_changed() {
			if (!this.$is_number(this.loading)) this.loading = 0;

			this.loading++;

			try {
				const assets_ids = this.filter.assets_ids;

				if (!assets_ids) {
					this.available_max_date = null;
					this.available_min_date = null;
					return;
				}

				if (!this.assets_ids_available_dates_cache)
					this.assets_ids_available_dates_cache = {};

				for (let index in assets_ids) {
					let asset_id = assets_ids[index];

					if (this.assets_ids_available_dates_cache[asset_id]) continue;

					this.assets_ids_available_dates_cache[asset_id] = {};
					this.assets_ids_available_dates_cache[asset_id].available_date_from =
						await AssetEnergyAvailableDateFinder.get_asset_energy_data_first_date(
							asset_id
						);
					this.assets_ids_available_dates_cache[asset_id].available_date_to =
						await AssetEnergyAvailableDateFinder.get_asset_energy_data_last_date(
							asset_id
						);
				}

				let min_date,
					max_date = null;
				for (let index in assets_ids) {
					let asset_id = assets_ids[index];

					if (!min_date) {
						min_date =
							this.assets_ids_available_dates_cache[asset_id]
								.available_date_from;
					} else if (
						moment(
							this.assets_ids_available_dates_cache[asset_id]
								.available_date_from
						).isBefore(min_date)
					) {
						min_date =
							this.assets_ids_available_dates_cache[asset_id]
								.available_date_from;
					}

					if (!max_date)
						max_date =
							this.assets_ids_available_dates_cache[asset_id].available_date_to;
					else if (
						moment(
							this.assets_ids_available_dates_cache[asset_id].available_date_to
						).isAfter(max_date)
					)
						max_date =
							this.assets_ids_available_dates_cache[asset_id].available_date_to;
				}

				this.available_max_date = max_date;
				this.available_min_date = min_date;
			} catch (error) {
			} finally {
				this.loading--;
			}
		},
		filter_resolution_changed() {
			let date_from, date_to;
			if (!this.filter.date) {
				date_to = this.max_datepicker_date;

				switch (this.filter.resolution) {
					case "day":
						if (
							moment(this.max_datepicker_date).diff(
								this.min_datepicker_date,
								"days"
							) > 7
						)
							date_from = moment(this.max_datepicker_date)
								.subtract(7, "days")
								.toDate();
						else date_from = this.min_datepicker_date;
						break;
					case "month":
						if (
							moment(this.max_datepicker_date).diff(
								this.min_datepicker_date,
								"months"
							) > 12
						)
							date_from = moment(this.max_datepicker_date)
								.subtract(12, "months")
								.toDate();
						else date_from = this.min_datepicker_date;
						break;
					case "year":
						if (
							moment(this.max_datepicker_date).diff(
								this.min_datepicker_date,
								"years"
							) > 3
						)
							date_from = moment(this.max_datepicker_date)
								.subtract(3, "years")
								.toDate();
						else date_from = this.min_datepicker_date;
						break;
				}
			} else {
				date_to = this.filter.date[1];

				switch (this.filter.resolution) {
					case "day":
						date_from = moment(date_to).subtract(3, "days").toDate();

						break;
					case "month":
						date_from = moment(date_to).subtract(3, "months").toDate();
						break;
					case "year":
						date_from = moment(date_to).subtract(1, "years").toDate();
						break;
				}

				if (moment(date_from).isBefore(this.min_datepicker_date)) {
					date_from = this.min_datepicker_date;
				}
			}

			this.filter.date = [date_from, date_to];
		},
		remove_selected_asset(asset_id) {
			const index_to_remove = this.filter.assets_ids.indexOf(asset_id);
			this.filter.assets_ids.splice(index_to_remove, 1);
		}
	},
	computed: {
		report_type_select_options() {
			const types = [
				"standard",
				"monthly_savings",
				"monthly_savings_2",
				"injection0",
				"data_download"
			];
			return types.map((item) => {
				return {
					key: item,
					label: this.$t("asset_reports.report_types." + item)
				};
			});
		},
		report_resolution_select_options() {
			const resolutions = ["day", "month", "year"];

			return resolutions.map((item) => {
				return {
					key: item,
					label: this.$t("asset_energy_panel." + item)
				};
			});
		},
		datepicker_max_range_days() {
			switch (this.filter.report_type) {
				case "data_download":
					switch (this.filter.resolution) {
						case "day":
							return 31;
							break;
						case "month":
							return 122;
							break;
						default:
							return null;
							break;
					}
					break;

				case ("standard", "injection0"):
					return 365;
					break;

				default:
					return null;
					break;
			}
		},
		max_datepicker_date() {
			return this.available_max_date;
		},
		min_datepicker_date() {
			if (
				this.filter.resolution != "day" ||
				moment(moment()).diff(this.available_min_date, "years") < 1
			)
				return this.available_min_date;
			else return moment().subtract(1, "years").toDate();
		},
		period_selected_range_string() {
			if (
				this.filter.report_type != "data_download" ||
				this.filter.resolution != "day" ||
				!this.filter.date ||
				!this.filter.date[0] ||
				!this.filter.date[1]
			)
				return null;

			const days_diff =
				moment(this.filter.date[1]).diff(this.filter.date[0], "days") + 1;

			return days_diff == 1
				? this.$t("general.days.single_day_with_number")
				: this.$t("general.days.multiple_days_with_numbers", {
						number: days_diff
				  });
		}
	},
	watch: {
		"filter.report_type"(new_value) {
			this.filter = {
				asset_id: null,
				report_type: new_value,
				assets_ids: null,
				date: null,
				resolution: null
			};
		},
		"filter.asset_id"(new_value) {
			if (new_value) this.filter_asset_id_changed();
		},
		"filter.assets_ids"() {
			this.filter_assets_ids_changed();
		},
		"filter.resolution"() {
			this.filter_resolution_changed();
		}
	}
};
</script>

<style lang="css" scoped>
#report-type-select {
	width: 250px;
}
</style>
