<template lang="html">
	<div>
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div v-else>
			<Header :title="$t('asset_reports.injection0_report.title')" />

			<ReportAssetPvData
				:asset="asset"
				:asset_reports="asset_pv_report"
				:filter="asset_reports_filter"
			/>

			<ReportAssetGenerationChart
				:asset_id="asset.id"
				:from="asset_reports_filter.date[0]"
				:to="asset_reports_filter.date[1]"
				:primary_color="asset_reports_color"
			/>

			<ReportAssetGenerationTable
				:asset_id="asset.id"
				:from="asset_reports_filter.date[0]"
				:to="asset_reports_filter.date[1]"
			/>

			<Footer :page_number="'1/' + (report_comment ? '3' : '2')" />
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import Header from "../../reports-shared-components/Header.vue";
import ReportAssetPvData from "../../reports-shared-components/ReportAssetPvData.vue";
import ReportAssetGenerationChart from "../../reports-shared-components/ReportAssetGenerationChart.vue";
import ReportAssetGenerationTable from "../../reports-shared-components/ReportAssetGenerationTable";

import Footer from "../../reports-shared-components/Footer.vue";

export default {
	name: "AssetInjection0ReportPage2",
	components: {
		Loader,
		Alert,
		Header,
		Footer,
		ReportAssetPvData,
		ReportAssetGenerationChart,
		ReportAssetGenerationTable
	},
	props: {
		asset: Object,
		asset_pv_report: Object,
		asset_reports_color: String,
		asset_reports_filter: Object,
		report_comment: String
	},
	data() {
		return {
			table_data: null,
			loading: null,
			error: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
		} catch (error) {
		} finally {
			this.loading = false;
		}
	}
};
</script>

<style lang="css" scoped></style>
