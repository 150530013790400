<template>
	<div class="grey3-bg rounded-m p-l p-r table-grid">
		<div></div>
		<div class="font-bold">
			{{ $t("pv_main_panels.measurements.forecasted") }}
		</div>
		<div class="font-bold">
			{{ $t("pv_main_panels.measurements.measured") }}
		</div>

		<div class="font-bold">Max</div>
		<div>
			<span>
				{{ formatter(forecast_stats.maximum) }}
			</span>
		</div>
		<div>
			<span>
				{{ formatter(measurement_stats.maximum) }}
			</span>
		</div>

		<div class="font-bold">Min</div>
		<div>
			<span>
				{{ formatter(forecast_stats.minimum) }}
			</span>
		</div>
		<div>
			<span>
				{{ formatter(measurement_stats.minimum) }}
			</span>
		</div>

		<div class="font-bold">
			{{ $t("pv_main_panels.measurements.average") }}
		</div>
		<div>
			<span>
				{{ formatter(forecast_stats.average) }}
			</span>
		</div>
		<div>
			<span>
				{{ formatter(measurement_stats.average) }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "ForecastAccuracyStatsTable",
	props: {
		forecast_stats: Object,
		measurement_stats: Object
	},
	methods: {
		formatter(number) {
			if (this.$is_number(number))
				return this.$format_number(number, 2) + " kW";
			else return "-";
		}
	}
};
</script>

<style scoped>
.table-grid {
	display: grid;
	grid-template-columns: max-content auto auto;
}

.table-grid > div {
	border-top: 1px solid #ccc; /* Borde horizontal */
	padding: 15px 30px 15px 0px; /* Espaciado interno de las celdas */
}

.table-grid > div:nth-child(-n + 3) {
	border-top: none; /* Quitar borde de la primera fila */
}
</style>
