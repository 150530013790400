<template lang="html">
	<div class="full-width full-height p-xxs">
		<div v-if="loading" class="m-t-xl">
			<Loader />
		</div>
		<div v-else class="full-width full-height">
			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div
				v-else
				class="full-width full-height grey2-bg flex-center-content relative"
			>
				<div class="asset-report-main-div relative">
					<div id="download-pdf-button">
						<div v-if="download_pdf_loading">
							<Loader size="20" />
						</div>
						<div v-else class="display-flex">
							<div class="btn btn-primary" v-on:click="toogle_report_comment">
								{{ $t("asset_reports.add_comment") }}
							</div>

							<div class="btn btn-primary m-l" v-on:click="download_pdf">
								{{ $t("asset_reports.download_pdf") }}
							</div>
						</div>
					</div>

					<div v-if="show_report_comment" id="report-comment">
						<div>
							<AddComment
								:report_comment="report_comment"
								v-on:comment_edited="comment_emmited"
								v-on:hide_edit_comment="toogle_report_comment"
							/>
						</div>
					</div>

					<div
						ref="pdf_content"
						class="asset-report-viewer overflow-y-scroll p-t p-b"
					>
						<div class="report-page">
							<Cover
								:report_title="$t('asset_reports.injection0_report.title')"
								:asset="asset"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
							/>
						</div>

						<div class="report-page">
							<Page1
								:asset_pv_report="asset_pv_report"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:report_comment="comment"
							/>
						</div>

						<div class="report-page">
							<Page2
								:asset_pv_report="asset_pv_report"
								:asset_reports_filter="filter"
								:asset_reports_color="asset_reports_color"
								:asset="asset"
								:report_comment="comment"
							/>
						</div>

						<div v-if="comment" class="report-page">
							<CommentPage :report_comment="comment" page_number="3/3" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import AddComment from "../reports-shared-components/AddComment";
import Cover from "../reports-shared-components/Cover";
import Page1 from "./report-pages/Page1.vue";
import Page2 from "./report-pages/Page2.vue";
import CommentPage from "../reports-shared-components/CommentPage";

import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder";
const AssetPvReportFinder = new AssetPvReportFinderClass();

export default {
	name: "AssetMonthlySavingsReport",
	components: {
		Alert,
		Loader,
		AddComment,
		Cover,
		Page1,
		Page2,
		CommentPage
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object
	},
	data() {
		return {
			loading: null,
			error: null,
			asset_pv_report: null,
			show_report_comment: false,
			comment: null,
			download_pdf_loading: false
		};
	},
	mounted() {
		this.generate_report();
	},
	methods: {
		async generate_report() {
			this.loading = true;
			this.error = null;

			try {
				const asset_pv_report = await AssetPvReportFinder.get_asset_pv_report(
					this.asset.id,
					this.filter.date[0],
					this.filter.date[1]
				);
				this.asset_pv_report = Object.assign({}, asset_pv_report);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async download_pdf() {
			try {
				this.download_pdf_loading = true;

				const html_element = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					html_element.innerHTML,
					"asset-injection0-report"
				);
			} catch (error) {
			} finally {
				this.download_pdf_loading = false;
			}
		},
		toogle_report_comment() {
			this.show_report_comment = !this.show_report_comment;
		},
		comment_emmited(comment) {
			this.comment = comment;
			this.show_report_comment = false;
		}
	},
	watch: {
		filter() {
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 50);
		}
	}
};
</script>

<style lang="css" scoped>
#download-pdf-button {
	position: absolute;
	right: 15px;
	top: 25px;
	z-index: 1;
}
#report-comment {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.5);
}
#report-comment > div {
	width: 75%;
	margin-left: 12.5%;
	margin-top: 20px;
}
</style>

<style lang="css">
.asset-monthly-savings-report-title {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 17px;
}
</style>
