<template>
	<div>
		<DataTable
			:items="formatted_assets_data_table"
			:fields="data_table_fields"
			:items_per_page="50"
		/>
	</div>
</template>

<script>
import DataTable from "../../../../sub_components/DataTable.vue";

export default {
	name: "AssetsDataTable",
	components: {
		DataTable
	},
	props: {
		assets_table_data: Array,
		resolution: String //day || month || year
	},
	data() {
		return {
			formatted_assets_data_table: this.assets_table_data.slice(1)
		};
	},
	computed: {
		data_table_fields() {
			let result = [
				{
					key: "0",
					label: this.$t("asset_energy_panel." + this.resolution),
					class: "no-wrap"
				}
			];

			const table_first_row = this.assets_table_data[0];

			for (let index = 1; index < table_first_row.length; index++) {
				result.push({
					key: String(index),
					label: table_first_row[index]
				});
			}

			return result;
		}
	}
};
</script>
