import BackendInterface from "@src/shared/infrastructure/backend-interface";
import BackendObjectConverter from "../../shared/infrastructure/backend-converters/backend-object-converter";

import moment from "moment";

export default class extends BackendInterface {
	constructor() {
		super();
	}

	async get_asset_energy_forecast_accuracy_metrics(asset_id, from, to) {
		if (
			moment(to).isAfter(new Date()) ||
			moment(new Date()).diff(from, "years") >= 1
		)
			return null;

		const url = "/api/asset/" + asset_id + "/forecast/accuracy-metrics";

		let query_params = {
			from: moment(from).format("YYYY-MM-DD"),
			to: moment(to).format("YYYY-MM-DD")
		};

		const result = await this.get(url, { query_params: query_params });
		const converted_result = BackendObjectConverter(result, {
			MAE: {
				local_name: "mae"
			},
			q95AE: {
				local_name: "q95ae"
			},
			MaxAE: {
				local_name: "max_ae"
			},
			RMSE: {
				local_name: "rmse"
			}
		});

		return converted_result;
	}
}
