<template>
	<div>
		<div
			class="rounded-s grey3-bg full-width relative overflow-hidden"
			style="height: 35px"
		>
			<div
				class="absolute full-width full-height rounded-s grey1-bg flex-center-content"
				v-bind:style="bar_style"
				style="top: 0; transition: width 0.3s linear"
			></div>

			<div
				v-if="show_percentage"
				class="full-width full-height absolute flex-center-content"
				style="z-index: 10"
			>
				<div v-bind:class="percentage < 50 ? 'grey1-color' : 'white'">
					{{ $format_number(percentage, 0) }}%
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LoaderBar",
	props: {
		percentage: Number,
		show_percentage: Boolean
	},
	computed: {
		bar_style() {
			return {
				width: this.percentage + "%"
			};
		}
	}
};
</script>
