<template lang="html">
	<div class="full-width full-height p-xxs">
		<div v-if="loading" class="full-width full-height flex-center-content">
			<Loader />
		</div>
		<div v-else class="full-width full-height">
			<div v-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div
				v-else-if="asset && asset_reports"
				class="full-width full-height relative"
			>
				<div class="full-width full-height grey2-bg flex-center-content">
					<div class="asset-report-main-div">
						<div id="report-buttons">
							<div class="display-flex">
								<div class="btn btn-primary" v-on:click="toogle_report_comment">
									{{ $t("asset_reports.add_comment") }}
								</div>
								<div class="m-l">
									<div v-if="downloading_pdf" class="flex-center-content">
										<Loader size="25" />
									</div>
									<button
										v-else
										class="btn btn-primary"
										v-on:click="download_pdf"
									>
										{{ $t("asset_reports.download_pdf") }}
									</button>
								</div>
							</div>
							<div class="clear"></div>
						</div>
						<div v-if="show_report_comment" id="report-comment">
							<div>
								<AddComment
									:report_comment="report_comment"
									v-on:comment_edited="comment_edited"
									v-on:hide_edit_comment="toogle_report_comment"
								/>
							</div>
						</div>
						<div id="pv-report-error" v-if="error">
							<Alert :message="error" />
						</div>
						<div
							ref="pdf_content"
							class="asset-report-viewer overflow-y-scroll p-t p-b"
						>
							<div class="report-page">
								<Cover
									:report_title="$t('asset_reports.pv_report.title')"
									:asset="asset"
									:asset_reports_filter="filter"
									:asset_reports_color="asset_reports_color"
								/>
							</div>
							<div class="report-page">
								<Page1
									:asset_reports="asset_reports"
									:asset="asset"
									:asset_reports_filter="filter"
									:report_comment="report_comment"
									:asset_reports_color="asset_reports_color"
								/>
							</div>
							<div class="report-page">
								<Page2
									:asset_reports="asset_reports"
									:report_comment="report_comment"
									:asset_reports_color="asset_reports_color"
								/>
							</div>
							<div v-if="report_comment" class="report-page">
								<CommentPage
									:report_comment="report_comment"
									page_number="3/3"
									:header_title="$t('asset_reports.pv_report.report_title')"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@alert";
import Loader from "@loader";

import AddComment from "../reports-shared-components/AddComment";
import Cover from "../reports-shared-components/Cover";
import Page1 from "./report-pages/Page1";
import Page2 from "./report-pages/Page2";
import CommentPage from "../reports-shared-components/CommentPage";

import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder.js";
const AssetPvReportFinder = new AssetPvReportFinderClass();

export default {
	name: "AssetPvReport",
	components: {
		Alert,
		Loader,
		AddComment,
		Cover,
		Page1,
		Page2,
		CommentPage
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object
	},
	data() {
		return {
			loading: null,
			error: null,
			asset_reports: null,
			show_report_comment: false,
			report_comment: null,
			downloading_pdf: null
		};
	},
	mounted() {
		this.generate_report(this.filter);
	},
	methods: {
		async generate_report(filter) {
			this.loading = true;
			this.error = null;

			try {
				const asset_reports = await AssetPvReportFinder.get_asset_pv_report(
					this.asset.id,
					filter.date[0],
					filter.date[1]
				);
				this.asset_reports = Object.assign({}, asset_reports);
			} catch (e) {
				this.error = e;
			} finally {
				this.loading = false;
			}
		},
		async download_pdf() {
			try {
				this.downloading_pdf = true;

				const custom_css =
					"/* -- START Page 1 -- */ #page1-content { position: relative; padding: 70px 0px; } #pv-report-page1-cover-image { margin-top: 25px; width: 100%; } #pv-report-page1-title { float: right; clear: both; font-size: 30px; margin-top: 30px; } #pv-report-page1-report-description { float: right; clear: both; width: 500px; font-size: 16px; margin-top: 10px; } /* -- END Page 1 -- */ /* -- START Page 2 -- */ #pv-report-page2-top-info { margin-top: 15px; font-size: 14px; line-height: 25px; } #pv-report-page2-report-map { width: 100%; height: 220px; } #pv-report-page2-chart { margin-top: 15px; width: 100%; } #pv-report-page2-chart > div:first-child { display: flex; } #pv-report-page2-chart > div:first-child > div { display: flex; justify-content: center; align-items: center; } #pv-report-page2-table { width: 100%; font-size: 12px; border-collapse: separate; border-spacing: 10px 5px; } #pv-report-page2-table { margin-top: 30px; } /* -- END Page 2 -- */ /* -- START Page 3 -- */ .pv-report-page3-big-numbers { font-size: 25px; font-weight: 700; } .pv-report-page3-ƒont-xs { font-size: 12px; } .pv-report-page3-ƒont-s { font-size: 14px; } #pv-report-page3-selected-period-summary { margin-top: 15px; width: 90%; margin-left: 5%; } #pv-report-page3-selected-period-summary table { width: 100%; table-layout: fixed; text-align: center; }/* -- END Page 3 -- */ /* -- START Page 4 -- */ #pv-report-page4-user-comment { text-align: justify; } /* -- END Page 4 -- */";
				const ref_content = this.$refs.pdf_content;
				await this.$download_asset_report_as_pdf_file(
					ref_content.innerHTML,
					"asset-pv-report",
					custom_css
				);
			} catch (error) {
			} finally {
				this.downloading_pdf = false;
			}
		},
		toogle_report_comment() {
			this.show_report_comment = !this.show_report_comment;
		},
		comment_edited(comment) {
			this.report_comment = comment;
			this.show_report_comment = false;
		}
	},
	watch: {
		filter(filter_new_value) {
			this.generate_report(filter_new_value);
		}
	}
};
</script>

<style>
@import "./report-pages/pv-report-styles.css";
</style>

<style lang="css" scoped>
#report-buttons {
	position: absolute;
	right: 15px;
	top: 20px;
	z-index: 1;
}
#report-comment {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.5);
}
#report-comment > div {
	width: 75%;
	margin-left: 12.5%;
	margin-top: 20px;
}
#pv-report-error {
	position: absolute;
	z-index: 1;
	top: 20px;
	left: 15px;
	width: 400px;
}
</style>
