<template>
	<div class="flex-auto-full-height-content">
		<div class="content">
			<div v-if="loading" class="flex-center-content">
				<div class="p-sm">
					<Loader size="30" />
				</div>
			</div>
			<div v-else-if="error" class="p-sm">
				<Alert :message="error" />
			</div>
			<div v-else-if="energy_system_custom_config">
				<div v-if="update_error" class="p-sm">
					<Alert :message="update_error" />
				</div>
				<div v-else-if="update_success" class="p-sm">
					<Alert type="success" message="general.update_successful" />
				</div>

				<div class="p-sm border-b">
					<div class="display-flex-space-between">
						<div class="flex-vertical-center">
							<span>
								{{
									$t(
										"entities.energy_system_custom_config.energy_measurements_download.optimize_all"
									)
								}}
							</span>
						</div>
						<div>
							<CustomCheckbox
								v-model="
									energy_system_custom_config.energy_measurements_download
										.optimize_all
								"
							/>
						</div>
					</div>
				</div>

				<div class="p-sm border-b">
					<div class="display-flex-space-between">
						<div class="flex-vertical-center">
							<span>
								{{ $t("entities.energy_system_custom_config.disable_alerts") }}
							</span>
						</div>
						<div>
							<CustomCheckbox
								v-model="energy_system_custom_config.disable_alerts"
							/>
						</div>
					</div>
				</div>

				<div class="p-sm">
					<EnergySystemCustomForecasting
						v-model="energy_system_custom_config.custom_forecasting"
					/>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="p-sm flex-horizontal-right border-t">
				<button
					class="btn btn-primary"
					v-on:click="update_energy_system_custom_config"
				>
					{{ $t("general.save") }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import CustomCheckbox from "../../../../../sub_components/form_components/CustomCheckbox.vue";
import EnergySystemCustomForecasting from "./EnergySystemCustomForecasting.vue";

import EnergySystemCustomConfigFinderClass from "@src/energy-system/application/energy-system-custom-config-finder.js";
import EnergySystemCustomConfigUpdaterClass from "@src/energy-system/application/energy-system-custom-config-updater.js";
const EnergySystemCustomConfigFinder =
	new EnergySystemCustomConfigFinderClass();
const EnergySystemCustomConfigUpdater =
	new EnergySystemCustomConfigUpdaterClass();

export default {
	name: "EnergySystemCustomConfig",
	components: {
		Loader,
		Alert,
		CustomCheckbox,
		EnergySystemCustomForecasting
	},
	props: {
		energy_system: Object
	},
	data() {
		return {
			loading: true,
			error: null,
			update_error: null,
			update_success: null,
			energy_system_custom_config: null,
			user_id: this.$route.params.user_id
		};
	},
	async mounted() {
		this.loading = true;

		try {
			const energy_system_custom_config =
				await EnergySystemCustomConfigFinder.get_energy_system_custom_config(
					this.user_id,
					this.energy_system.id
				);

			this.energy_system_custom_config = JSON.parse(
				JSON.stringify(energy_system_custom_config)
			);

			if (!this.energy_system_custom_config.custom_forecasting)
				this.energy_system_custom_config.custom_forecasting = {
					enabled: false,
					model_order: []
				};
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async update_energy_system_custom_config() {
			this.loading = true;
			this.update_success = null;
			this.update_error = null;

			try {
				if (
					this.energy_system_custom_config.custom_forecasting &&
					this.energy_system_custom_config.custom_forecasting.model_order
				) {
					this.energy_system_custom_config.custom_forecasting.model_order =
						this.energy_system_custom_config.custom_forecasting.model_order.filter(
							(item) => item.model_type && item.weather_provider_name
						);
				}

				const energy_system_custom_config = Object.assign(
					{},
					this.energy_system_custom_config
				);

				const updated_data =
					await EnergySystemCustomConfigUpdater.update_energy_system_custom_config(
						this.user_id,
						this.energy_system.id,
						energy_system_custom_config
					);

				this.energy_system_custom_config = Object.assign({}, updated_data);
				if (!this.energy_system_custom_config.custom_forecasting)
					this.energy_system_custom_config.custom_forecasting = {
						enabled: false,
						model_order: null
					};

				this.update_success = true;

				setTimeout(() => {
					this.update_success = null;
				}, 8000);
			} catch (error) {
				this.update_error = error;

				setTimeout(() => {
					this.update_error = null;
				}, 8000);
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>
