export default {
	async execute_promise_functions_in_batches(
		promise_functions,
		batch_size,
		promises_execution_data = null
	) {
		const results = [];
		const queue = [...promise_functions];
		const active_promises = [];

		if (promises_execution_data == null) promises_execution_data = {};

		promises_execution_data.total_promises = promise_functions.length;
		promises_execution_data.processed_promises = 0;
		promises_execution_data.processed_percentage = 0;

		async function process_next() {
			if (queue.length === 0) return;

			const promiseFunction = queue.shift();
			const result = await promiseFunction();
			results.push(result);

			promises_execution_data.processed_promises++;
			promises_execution_data.processed_percentage =
				(promises_execution_data.processed_promises * 100) /
				promises_execution_data.total_promises;

			await process_next();
		}

		for (let i = 0; i < batch_size; i++) {
			active_promises.push(process_next());
		}

		await Promise.all(active_promises);
		return results;
	}
};
