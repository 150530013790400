<template>
	<div>
		<div v-if="loading">
			<Loader size="25" />
		</div>
		<div v-else-if="error" class="p-sm">
			<Alert :message="error" />
		</div>
		<div
			v-else-if="asset_energy_forecast_accuracy_metrics"
			class="display-flex-space-between p-t"
		>
			<div
				v-if="asset_energy_forecast_accuracy_metrics.production"
				class="display-flex-vertical-space-between gap-s"
			>
				<div class="text-center font-xl text-uppercase font-700">
					{{ $t("asset_energy_panel.energy_production") }}
				</div>

				<div class="display-flex gap-s">
					<ForecastAccuracyStatsTable
						:forecast_stats="
							asset_energy_forecast_accuracy_metrics.production.forecast_stats
						"
						:measurement_stats="
							asset_energy_forecast_accuracy_metrics.production
								.measurement_stats
						"
					/>

					<ForecastAccuracyMetricsTable
						:metrics="asset_energy_forecast_accuracy_metrics.production.metrics"
					/>
				</div>
			</div>

			<div
				v-if="asset_energy_forecast_accuracy_metrics.consumption"
				class="display-flex-vertical-space-between gap-s"
			>
				<div class="text-center font-xl text-uppercase font-700">
					{{ $t("asset_energy_panel.energy_consumption") }}
				</div>

				<div class="display-flex gap-s">
					<ForecastAccuracyStatsTable
						:forecast_stats="
							asset_energy_forecast_accuracy_metrics.consumption.forecast_stats
						"
						:measurement_stats="
							asset_energy_forecast_accuracy_metrics.consumption
								.measurement_stats
						"
					/>
					<ForecastAccuracyMetricsTable
						:metrics="
							asset_energy_forecast_accuracy_metrics.consumption.metrics
						"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import ForecastAccuracyStatsTable from "./ForecastAccuracyStatsTable.vue";
import ForecastAccuracyMetricsTable from "./ForecastAccuracyMetricsTable.vue";

import AssetEnergyForecastAccuracyMetricsFinderClass from "../../../../../../../../src/forecast/application/asset-energy-forecast-accuracy-metrics-finder.js";
const AssetEnergyForecastAccuracyMetricsFinder =
	new AssetEnergyForecastAccuracyMetricsFinderClass();

export default {
	name: "AssetEnergyForecastAccuracyMetrics",
	components: {
		Loader,
		Alert,
		ForecastAccuracyStatsTable,
		ForecastAccuracyMetricsTable
	},
	props: {
		asset_id: String,
		date_from: Date,
		date_to: Date
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_energy_forecast_accuracy_metrics: null
		};
	},
	mounted() {
		this.set_asset_energy_forecast_accuracy_metrics();
	},
	methods: {
		async set_asset_energy_forecast_accuracy_metrics() {
			this.loading = true;

			try {
				this.asset_energy_forecast_accuracy_metrics =
					await AssetEnergyForecastAccuracyMetricsFinder.get_asset_energy_forecast_accuracy_metrics(
						this.asset_id,
						this.date_from,
						this.date_to
					);
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		}
	}
};
</script>

<style scoped>
.table-grid2 {
	display: grid;
	grid-template-columns: max-content auto;
}

.table-grid2 > div {
	border-top: 1px solid #ccc; /* Borde horizontal */
	padding: 15px 30px 15px 0px; /* Espaciado interno de las celdas */
}

.table-grid2 > div:nth-child(-n + 2) {
	border-top: none; /* Quitar borde de la primera fila */
}
</style>
