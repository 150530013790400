import PromisesUtil from "../../../src/shared/application/utils/promises-util.js";

//VueJS plugins doc: https://vuejs.org/v2/guide/plugins.html
export default {
	install: function (app, options) {
		app.config.globalProperties.$execute_promise_functions_in_batches =
			async function (
				promise_functions,
				batch_size,
				promises_execution_data = null
			) {
				return await PromisesUtil.execute_promise_functions_in_batches(
					promise_functions,
					batch_size,
					promises_execution_data
				);
			};
	}
};
