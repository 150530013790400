import AssetEnergyForecastAccuracyMetricsFinderInterfaceClass from "../infrastructure/asset-energy-forecast-accuracy-metrics-finder-interface.js";
import AssetEnergyForecastAccuracyMetricsValidator from "../domain/energy-forecast-accuracy-metrics-validator.js";

import AppError from "@classes/errors/app_error";

export default class {
	constructor() {
		this.validator = AssetEnergyForecastAccuracyMetricsValidator;
		this.backend_interface =
			new AssetEnergyForecastAccuracyMetricsFinderInterfaceClass();
	}

	async get_asset_energy_forecast_accuracy_metrics(asset_id, from, to) {
		try {
			let result =
				await this.backend_interface.get_asset_energy_forecast_accuracy_metrics(
					asset_id,
					from,
					to
				);

			return this.validator.validate(result);
		} catch (e) {
			throw new AppError(
				"error_get_asset_energy_forecast_accuracy_metrics",
				"general.errors.general_error",
				e
			);
		}
	}
}
