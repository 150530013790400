<template lang="html">
	<div class="full-width relative white-bg">
		<Header :title="$t('asset_reports.asset_monthly_savings_report.title')" />

		<div v-if="loading" class="m-t-xl">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else>
			<div class="m-t">
				<div>
					<span class="asset-monthly-savings-report-title text-uppercase">
						{{
							$t(
								"asset_reports.asset_monthly_savings_report.page3.anual_tech_data"
							)
						}}
					</span>
				</div>

				<div>
					<table class="report-table">
						<thead class="font-bold">
							<th>
								<span>
									{{ results_year }}
								</span>
							</th>
							<th>
								<span>
									{{
										$t(
											"asset_reports.asset_monthly_savings_report.page3.total_consumption"
										)
									}}
									(MWh)
								</span>
							</th>
							<th>
								<span>
									{{
										$t(
											"asset_reports.asset_monthly_savings_report.page3.estimated_energy"
										)
									}}
									(MWh)
								</span>
							</th>
							<th>
								<span>
									{{
										$t(
											"asset_reports.asset_monthly_savings_report.page3.self_consumption_energy"
										)
									}}
									(MWh)
								</span>
							</th>
							<th>
								<span>
									{{
										$t(
											"asset_reports.asset_monthly_savings_report.page3.pv_consumption"
										)
									}}
									(%)
								</span>
							</th>
							<th>
								<div>
									<span
										>{{
											$t(
												"asset_reports.asset_monthly_savings_report.page3.monthly_ghi"
											)
										}}
										(kWh/m<sup>2</sup>)
									</span>
								</div>
							</th>
						</thead>
						<tbody>
							<tr v-for="item in year_monthly_energy">
								<td class="font-bold">
									<span>{{ $format_date_to_month_string(item.time) }}</span>
								</td>
								<td class="text-right">
									<span>
										{{
											item.energy_consumption != null
												? $format_number(
														item.energy_consumption / 1000,
														item.energy_consumption < 1000 ? 3 : 2
												  )
												: ""
										}}
									</span>
								</td>
								<td class="text-right">
									{{
										item.estimated_energy_production != null
											? $format_number(
													item.estimated_energy_production / 1000,
													item.estimated_energy_production < 1000 ? 3 : 2
											  )
											: ""
									}}
								</td>
								<td class="text-right">
									<span>
										{{
											item.energy_self_consumption
												? $format_number(
														item.energy_self_consumption / 1000,
														item.energy_self_consumption < 1000 ? 3 : 2
												  )
												: ""
										}}
									</span>
								</td>
								<td class="text-right">
									<span>
										{{
											item.energy_self_consumption != null
												? $format_number(
														(item.energy_self_consumption * 100) /
															item.energy_consumption,
														0
												  )
												: ""
										}}
									</span>
								</td>

								<td class="text-right">
									<span>
										{{ item.ghi != null ? $format_number(item.ghi, 0) : "" }}
									</span>
								</td>
							</tr>

							<tr v-if="total_year_energy">
								<td class="font-bold text-uppercase">
									<span>
										{{
											$t(
												"asset_reports.asset_monthly_savings_report.page3.total_accumulated"
											)
										}}
									</span>
								</td>
								<td class="text-right">
									<span>
										{{
											$format_number(
												total_year_energy.energy_consumption / 1000,
												total_year_energy.energy_consumption < 1000 ? 3 : 2
											)
										}}
									</span>
								</td>
								<td class="text-right">
									<span>
										{{
											total_year_energy.estimated_energy_production
												? $format_number(
														total_year_energy.estimated_energy_production /
															1000,
														total_year_energy.estimated_energy_production < 1000
															? 3
															: 2
												  )
												: null
										}}
									</span>
								</td>
								<td class="text-right">
									<span>
										{{
											$format_number(
												total_year_energy.energy_self_consumption / 1000,
												total_year_energy.energy_self_consumption < 1000 ? 3 : 2
											)
										}}
									</span>
								</td>
								<td class="text-right">
									<span>
										{{
											$format_number(
												(total_year_energy.energy_self_consumption * 100) /
													total_year_energy.energy_consumption,
												0
											)
										}}
									</span>
								</td>

								<td class="text-right">
									<span>
										{{ $format_number(total_year_energy.ghi, 0) }}
									</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<YearMonthlySavingsChart
				class="m-t-xl"
				:from="savings_chart_data.from"
				:to="filter.date"
				:asset_id="asset.id"
				:asset_reports_color="asset_reports_color"
				:height_in_px="300"
			/>

			<div v-if="month_saved_data" class="m-t">
				<div class="asset-monthly-savings-report-title">
					{{
						$t(
							"asset_reports.asset_monthly_savings_report.page2.self_consumed_energy_equivalent_to"
						)
					}}:
				</div>

				<div class="display-flex-space-between m-t">
					<div class="full-width p-r-xs">
						<div class="equivalent-box display-flex full-height">
							<div class="flex-vertical-center" style="color: var(--green1)">
								<Icon icon="tree" height="30px" size="30" />
							</div>
							<div
								class="flex-vertical-center font-bold m-l"
								style="font-size: 14px"
							>
								{{
									$t(
										"asset_reports.asset_monthly_savings_report.page2.co2_tree_equivalent",
										{
											number_of_trees:
												month_saved_data.needed_trees_to_process_co2
										}
									)
								}}
							</div>
						</div>
					</div>

					<div v-if="month_saved_data.co2" class="full-width p-l-xs">
						<div class="equivalent-box display-flex full-height">
							<div class="flex-vertical-center" style="color: #abb1b7">
								<Icon icon="cloud" height="30px" size="30" />
							</div>
							<div
								class="font-bold m-l flex-vertical-center"
								style="font-size: 14px"
							>
								<span
									v-html="
										$t(
											'asset_reports.asset_monthly_savings_report.page2.co2_kg_equivalemt',
											{ kg: month_saved_data.co2 }
										)
									"
								>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer :page_number="'3/' + (comment ? '4' : '3')" />
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";
import Header from "../../reports-shared-components/Header.vue";
import Footer from "../../reports-shared-components/Footer.vue";

import YearMonthlySavingsChart from "./charts/YearMonthlySavingsChart";

import moment from "moment";
import AssetMonthlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
import LocationEnergyMeasurementProvidersServiceClass from "@services/location-services/location-energy-measurement-providers-service";
const AssetMonthlyEnergyFinder = new AssetMonthlyEnergyFinderClass();
const LocationEnergyMeasurementProvidersService =
	new LocationEnergyMeasurementProvidersServiceClass();

import AssetPvReportFinderClass from "@src/asset-pv-report/application/asset-pv-report-finder";
const AssetPvReportFinder = new AssetPvReportFinderClass();

import AssetReanalysisWeatherFinderClass from "@src/asset-weather/application/asset-reanalysis-weather-finder.js";
const AssetReanalysisWeatherFinder = new AssetReanalysisWeatherFinderClass();

export default {
	name: "AssetMonhtlyReportPage2",
	components: {
		Loader,
		Alert,
		Header,
		Footer,
		YearMonthlySavingsChart
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object,
		comment: String || null
	},
	data() {
		return {
			loading: true,
			error: null,
			asset_pv_report: null,
			year_monthly_energy: null,
			total_year_energy: null,
			month_saved_data: null,
			results_year: null,
			savings_chart_data: {
				from: moment(this.filter.date).startOf("year"),
				to: moment(this.filter.date).endOf("year")
			}
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.month_saved_data = await this.get_month_saved_data();
			this.asset_pv_report = await this.get_asset_pv_report();
			this.year_monthly_energy = await this.get_year_monthly_energy();
			this.total_year_energy = this.get_total_year_energy();
			this.results_year =
				this.year_monthly_energy && this.year_monthly_energy[0].time
					? this.$format_date_to_year_number(this.year_monthly_energy[0].time)
					: null;
		} catch (error) {
			this.error = error;
		} finally {
			setTimeout(() => {
				this.loading = false;
			}, 500);
		}
	},
	methods: {
		async get_year_monthly_energy() {
			const monthly_energy_data =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy(this.asset.id);

			const filter_year_data = monthly_energy_data.filter((item) =>
				moment(item.time).isSame(this.filter.date, "year")
			);

			const selected_period_estimated_production =
				this.asset_pv_report &&
				this.asset_pv_report.energy_production_summary &&
				this.asset_pv_report.energy_production_summary
					.selected_period_energy_production
					? this.asset_pv_report.energy_production_summary.selected_period_energy_production.map(
							(item) => {
								return {
									time: item.date,
									estimated_energy_production: item.estimated_energy_production
								};
							}
					  )
					: null;

			let result = [];
			const from_month = Number(moment(filter_year_data[0].time).format("M"));
			const to_month = Number(moment(this.filter.date).format("M"));

			for (let index = from_month; index <= to_month; index++) {
				const month_data = filter_year_data.find(
					(item) => moment(item.time).format("M") == index
				);

				let result_item = month_data
					? month_data
					: {
							time: moment(this.filter.date)
								.month(index - 1)
								.toDate(),
							energy_production: null,
							energy_consumption: null,
							energy_from_grid: null,
							energy_to_grid: null,
							energy_self_consumption: null,
							rate_self_consumption: null,
							rate_self_sufficiency: null
					  };

				const month_estimated_production = selected_period_estimated_production
					? selected_period_estimated_production.find(
							(item) => moment(item.time).format("M") == index
					  )
					: null;

				result_item.estimated_energy_production = month_estimated_production
					? month_estimated_production.estimated_energy_production
					: null;

				result.push(result_item);
			}

			const year_monthly_ghi =
				await AssetReanalysisWeatherFinder.get_asset_monthly_reanalysis_weaher(
					this.asset.id,
					this.savings_chart_data.from,
					this.filter.date
				);
			result.forEach((item) => {
				const item_ghi = year_monthly_ghi.find((ghi_item) =>
					moment(item.time).isSame(ghi_item.time, "month")
				);
				item.ghi = item_ghi != undefined ? item_ghi.ghi : null;
			});

			return result;
		},
		get_total_year_energy() {
			let result = {
				energy_production: null,
				energy_consumption: null,
				energy_from_grid: null,
				energy_to_grid: null,
				energy_self_consumption: null
			};

			const props_to_add = [
				"energy_consumption",
				"energy_production",
				"energy_from_grid",
				"energy_to_grid",
				"energy_self_consumption",
				"estimated_energy_production",
				"ghi"
			];

			this.year_monthly_energy.forEach((item) => {
				props_to_add.forEach((prop) => {
					if (item[prop]) {
						if (result[prop] != null) {
							result[prop] += item[prop];
						} else {
							result[prop] = item[prop];
						}
					}
				});
			});

			return result;
		},
		async get_month_saved_data() {
			const monthly_energy_data =
				await AssetMonthlyEnergyFinder.get_asset_monthly_energy(this.asset.id);
			const filter_month_energy_data = monthly_energy_data.find((item) =>
				moment(item.time).isSame(this.filter.date, "month")
			);

			if (!filter_month_energy_data) return null;

			let saved_data =
				LocationEnergyMeasurementProvidersService.get_saved_from_aggregated(
					filter_month_energy_data
				);
			saved_data.needed_trees_to_process_co2 = this.$format_number(
				saved_data.co2 * (1000 / (12 * 150)) * 0.55,
				0
			);
			saved_data.co2 = saved_data.co2
				? this.$format_number(saved_data.co2, 0)
				: null;

			return saved_data;
		},
		async get_asset_pv_report() {
			const asset_reports = await AssetPvReportFinder.get_asset_pv_report(
				this.asset.id,
				moment(this.filter.date).startOf("year").toDate(),
				moment(this.filter.date).endOf("month").toDate()
			);
			return asset_reports ? Object.assign({}, asset_reports) : null;
		}
	}
};
</script>

<style lang="css" scoped>
.equivalent-box {
	background-color: #e6e6e7;
	border-radius: 5px;
	padding: 10px 50px 10px 15px;
}
.report-table {
	border-collapse: collapse;
	border: 1px solid #efeff0;
	font-size: 13px;
}
.report-table th,
.report-table td {
	padding: 5px 5px;
	border: 1px solid #efeff0;
}

.report-table thead,
.report-table td:first-child {
	background-color: #e6e6e7;
}
</style>
