<template>
	<div class="grey3-bg rounded-m p-l p-r table-grid">
		<div class="display-flex gap-xs">
			<div class="font-bold">RMSE</div>
			<Tooltip
				position="top"
				tooltip_text="root-mean-square error"
				:single_line_tooltip_text="true"
			/>
		</div>
		<div>
			<span>{{ formatter(metrics.rmse) }}</span>
		</div>

		<div class="display-flex gap-xs">
			<div class="font-bold">MAE</div>
			<Tooltip
				position="top"
				tooltip_text="Mean Absolute Error"
				:single_line_tooltip_text="true"
			/>
		</div>
		<div>
			<span>{{ formatter(metrics.mae) }}</span>
		</div>

		<div class="display-flex gap-xs">
			<div class="font-bold">Max AE</div>
			<Tooltip
				position="top"
				tooltip_text="Maximum Absolute Error"
				:single_line_tooltip_text="true"
			/>
		</div>
		<div>
			<span>{{ formatter(metrics.max_ae) }}</span>
		</div>

		<div class="display-flex gap-xs">
			<div class="font-bold">q95 AE</div>
			<Tooltip
				position="top"
				tooltip_text="95th percentile of Absolute Error"
				:single_line_tooltip_text="true"
			/>
		</div>
		<div>
			<span>{{ formatter(metrics.q95ae) }}</span>
		</div>
	</div>
</template>

<script>
import Tooltip from "../../../../../sub_components/Tooltip.vue";

export default {
	name: "ForecastAccuracyStatsTable",
	components: {
		Tooltip
	},
	props: {
		metrics: Object
	},
	methods: {
		formatter(number) {
			if (this.$is_number(number)) return this.$format_number(number, 2) + "%";
			else return "-";
		}
	}
};
</script>

<style scoped>
.table-grid {
	display: grid;
	grid-template-columns: max-content auto;
}
.table-grid > div {
	border-top: 1px solid #ccc; /* Borde horizontal */
	padding: 15px 30px 15px 0px; /* Espaciado interno de las celdas */
}
.table-grid > div:nth-child(-n + 2) {
	border-top: none; /* Quitar borde de la primera fila */
}
</style>
