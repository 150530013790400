<template lang="html">
	<div class="full-width relative white-bg">
		<Header :title="$t('asset_reports.asset_monthly_savings_report.title')" />

		<div v-if="loading" class="m-t-xl">
			<Loader size="30" />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else>
			<div>
				<div class="display-flex">
					<div class="font-bold">
						{{ $t("asset_reports.asset_monthly_savings_report.page1.month") }}:
					</div>
					<div class="m-l-xs">
						{{ $format_date_to_month(filter.date) }}
					</div>
				</div>

				<div class="asset-monthly-savings-report-title m-t-xs">
					{{
						$t("asset_reports.asset_monthly_savings_report.page1.asset_data")
					}}
				</div>

				<div class="display-flex">
					<div class="font-bold">
						{{
							$t("asset_reports.asset_monthly_savings_report.page1.asset_name")
						}}:
					</div>
					<div class="m-l-xs">
						{{ asset.name }}
					</div>
				</div>

				<div class="display-flex" v-if="asset_other_data.address">
					<div class="font-bold">
						{{
							$t(
								"asset_reports.asset_monthly_savings_report.page1.asset_address"
							)
						}}:
					</div>
					<div class="m-l-xs">
						{{ asset_other_data.address }}
					</div>
				</div>

				<div class="display-flex" v-if="energy_systems_min_commisioning_date">
					<div class="font-bold">
						{{
							$t(
								"asset_reports.asset_monthly_savings_report.page1.commissioning_date"
							)
						}}:
					</div>
					<div class="m-l-xs">
						{{ $format_date_to_day(energy_systems_min_commisioning_date) }}
					</div>
				</div>
			</div>

			<div class="p-t p-b">
				<hr />
			</div>

			<div>
				<div class="asset-monthly-savings-report-title m-t-xs">
					{{ $t("asset_reports.asset_monthly_savings_report.page1.title_1") }}
				</div>

				<div class="display-flex-space-between m-t" v-if="filter_month_savings">
					<div>
						<MonthSavingsColumnChart
							:filter_month_savings="filter_month_savings"
							:filter_month_energy="filter_month_energy"
							:asset_reports_color="asset_reports_color"
						/>
					</div>
					<div>
						<MonthEnergyColumnChart
							:filter_month_energy="filter_month_energy"
							:asset_reports_color="asset_reports_color"
						/>
					</div>
				</div>
			</div>

			<!-- TMP -> DISABLE BY THE MOMENT -->
			<!-- <div
				class="m-t-xl"
				v-if="
					filter_month_energy &&
					filter_month_energy.energy_from_grid &&
					filter_month_energy.energy_self_consumption
				"
			>
				<div class="asset-monthly-savings-report-title m-t-xs">
					{{ $t("asset_energy_panel.energy_consumption") }}
				</div>

				<MonthEnergyConsumptionChart
					:filter_month_energy="filter_month_energy"
					:asset_reports_color="asset_reports_color"
				/>
			</div> -->

			<Footer :page_number="'1/' + (comment ? '4' : '3')" />
		</div>
	</div>
</template>

<script>
import Loader from "@loader";
import Alert from "@alert";

import Header from "../../reports-shared-components/Header.vue";
import Footer from "../../reports-shared-components/Footer.vue";
import MonthSavingsColumnChart from "./charts/MonthSavingsColumnChart";
import MonthEnergyColumnChart from "./charts/MonthEnergyColumnChart";
import MonthEnergyConsumptionChart from "./charts/MonthEnergyConsumptionChart";

import moment from "moment";
import AssetOtherDataFinderClass from "@src/asset/application/asset-other-data-finder";
import AssetEnergySystemsFinderClass from "@src/energy-system/application/asset-energy-systems-finder";
import AssetSavingsMonhtlyFinderClass from "@src/asset-savings/application/asset-savings-monthly-finder";
import AssetMonhtlyEnergyFinderClass from "@src/asset-energy-data/application/asset-monthly-energy-finder";
const AssetOtherDataFinder = new AssetOtherDataFinderClass();
const AssetEnergySystemsFinder = new AssetEnergySystemsFinderClass();
const AssetSavingsMonhtlyFinder = new AssetSavingsMonhtlyFinderClass();
const AssetMonhtlyEnergyFinder = new AssetMonhtlyEnergyFinderClass();

export default {
	name: "AssetMonhtlyReportPage1",
	components: {
		Loader,
		Alert,
		Header,
		Footer,
		MonthSavingsColumnChart,
		MonthEnergyColumnChart,
		MonthEnergyConsumptionChart
	},
	props: {
		filter: Object,
		asset_reports_color: String,
		asset: Object,
		comment: String || null
	},
	data() {
		return {
			loading: false,
			error: null,
			asset_other_data: {},
			energy_systems_min_commisioning_date: null,
			filter_month_savings: null,
			filter_month_energy: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.asset_other_data = await this.get_asset_other_data();
			this.energy_systems_min_commisioning_date =
				await this.get_energy_systems_min_commisioning_date();
			this.filter_month_savings = await this.get_filter_month_savings();
			this.filter_month_energy = await this.get_filter_month_energy();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async get_asset_other_data() {
			return await AssetOtherDataFinder.get_asset_other_data(this.asset.id);
		},
		async get_energy_systems_min_commisioning_date() {
			const asset_energy_systems =
				await AssetEnergySystemsFinder.get_asset_energy_systems(this.asset.id);

			const min_commisioning_date = Math.min(
				...asset_energy_systems.map(
					(energy_system) => new Date(energy_system.commissioning_date)
				)
			);

			return this.$is_date(min_commisioning_date)
				? new Date(min_commisioning_date)
				: null;
		},
		async get_filter_month_savings() {
			const asset_monthly_savings =
				await AssetSavingsMonhtlyFinder.get_asset_savings_monthly(
					this.asset.id
				);

			return asset_monthly_savings.find((item) =>
				moment(item.month).isSame(this.filter.date, "month")
			);
		},
		async get_filter_month_energy() {
			const asset_monthy_energy =
				await AssetMonhtlyEnergyFinder.get_asset_monthly_energy(this.asset.id);

			return asset_monthy_energy.find((item) =>
				moment(item.time).isSame(this.filter.date, "month")
			);
		}
	}
};
</script>

<style lang="css" scoped></style>
