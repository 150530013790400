<template>
	<div class="full-width full-height white-bg relative">
		<div
			v-if="show_popup"
			class="absolute full-size-popup-bg full-width full-height flex-center-content"
		>
			<router-view
				v-on:close="asset_id_to_remove = null"
				v-on:removing_item="loading = true"
			></router-view>
		</div>
		<div class="flex-auto-full-height-content">
			<div class="header">
				<AssetsGroupSummary
					ref="AssetsGroupSummary"
					:assets_group_id="selected_assets_group_id"
				/>
			</div>
			<div class="content">
				<AssetsGroupContent
					v-on:selected_assets_group_id_changed="
						selected_assets_group_id_changed
					"
					v-on:force_data_reload="force_data_reload"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@sub_components/PageHeader";
import AssetsGroupSummary from "./assets-group-summary/AssetsGroupSummary.vue";
import AssetsGroupContent from "./AssetsGroupContent.vue";

export default {
	name: "AssetsDashboard",
	components: {
		PageHeader,
		AssetsGroupSummary,
		AssetsGroupContent
	},
	data() {
		return {
			selected_assets_group_id: "default"
		};
	},
	computed: {
		show_popup() {
			const allowed_routes_for_popup = [
				"remove-asset",
				"asset-systems",
				"system-assets"
			];

			return allowed_routes_for_popup.indexOf(this.$route.name) != -1;
		}
	},
	methods: {
		selected_assets_group_id_changed(new_value) {
			this.selected_assets_group_id = new_value;
		},
		force_data_reload() {
			this.$refs.AssetsGroupSummary.load_assets_group_data(true);
		}
	}
};
</script>

<style scoped></style>
